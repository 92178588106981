<template>
  <div class='container-modal-add'>
    <div class="container-modal-add">
      <div class="form-new-brand-add">
        <div class="header-new-brand-add">
          <p class="background-icon"><span class="exclamation-icon">!</span></p>
          <app-heading level="h6">{{ $t('bvs.want_to_add') }}</app-heading>
          <app-heading level="h7" class="subtitle-modal">{{ $t('bvs.subtitle_to_remove') }}</app-heading>
        </div>
        <div class="footer-brand-add">
          <button class="btn-brand-add" @click="addBrand()">
            <p v-if="!loading">{{ $t('bvs.add') }}</p>
            <Circle2 v-else class="spinner-social"/>
          </button>
          <button class="btn-brand-add cancel" @click="closeModal()">{{ $t('bvs.cancel') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { socialMediaService } from '@/_services'

export default {
  name: 'ModalAddBrand',
  props: ['brand'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      loading: false,
      nameCustomBrand: ''
    }
  },
  mounted () {
    const modal = document.querySelector('.form-new-brand-add')
    setTimeout(() => { modal.classList.add('display') }, 100)
  },
  computed: {
    account () {
      return this.$store.getters['account/getAccount']
    }
  },
  methods: {
    async addBrand () {
      if (this.brand && this.brand.id) {
        this.loading = true
        const res = await socialMediaService.createBrands([this.brand])
        if (res.message === 'Limit of terms reached') {
          this.$store.commit('alerts/alert', {
            message: this.$t('bvs.limit_reached'),
            position: 'bottomRight',
            showAlert: true
          })
        } else {
          this.$store.commit('alerts/alert', {
            message: this.$t('bvs.create_success'),
            position: 'bottomRight',
            showAlert: true
          })

          setTimeout(() => {
            this.closeModal()
            this.$store.commit('account/updateBrandsTracking', res.brandsTracking)
            this.$root.$emit('remove-brand-from-search', this.brand)
            this.$emit('scroll-to-brand')
            this.loading = false
          }, 800)
        }
      }
    },
    closeModal () {
      const modal = document.querySelector('.form-new-brand-add')
      modal.classList.remove('display')
      setTimeout(() => { this.$root.$emit('close-brand-action-modals') }, 300)
    }
  }
}
</script>

<style lang='scss'>
.container-modal-add {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0000004a;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-new-brand-add {
    opacity: 0;
    transition: opacity 0.3s;
    user-select: none;
    position: relative;
    width: 25%;
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    .header-new-brand-add {
      width: 100%;
      height: 80%;
      border-bottom: 1px solid #cacaca;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .background-icon {
        width: 50px;
        height: 50px;
        background: #00800021;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .exclamation-icon {
          width: 50%;
          height: 50%;
          border: 1px solid #3dc93d;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 17px;
          font-weight: 500;
          color: #3dc93d;
        }
      }
      h6 {
        text-align: center;
        font-weight: 500;
        color: #454548;
        margin: 15px 0 !important;
      }
      .subtitle-modal {
        color: #858585;
        font-size: 13px;
      }
    }
    .footer-brand-add {
      width: 100%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F0F2F5;
      .btn-brand-add {
        width: 90px;
        height: 30px;
        margin: 0 5px;
        padding: 5px 7px;
        border-radius: 3px;
        background: var(--accent-color);
        color: var(--default-text-color);
        font-weight: 500;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        .spinner-social {
          width: 20px !important;
          height: 20px !important;
          border-width: 2px !important;
          border-color: var(--accent-color) var(--default-text-color) var(--default-text-color) !important;
        }
      }
      .cancel {
        background: #ffffff;
        color: var(--accent-color);
        border: 1px solid #cacaca;
        font-weight: 600;
      }
    }
  }
  .display {
    opacity: 1;
  }
}
</style>
